<template>
  <div>
    <el-row
      style="
        background-color: blue;
        height: 50px;
        display: flex;
        align-items: center;
      "
    >
      <el-col :span="4" :offset="1">
        <div
          style="font-weight: bold; font-size: 20px; height: 100%; color: white"
        >
          社会化服务监测
        </div>
      </el-col>
      <el-col :span="4" :offset="15">
        <el-button type="text" icon="el-icon-data-board" @click="dataAnalysis"
          >数据分析</el-button
        >
      </el-col>
    </el-row>
    <div style="margin: 20px 20px 0 20px">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-card>
            <div>
              <div style="font-weight: bold; font-size: 20px">
                全县-在线农机
              </div>
            </div>
            <div
              style="
                font-weight: bold;
                font-size: 20px;
                color: blue;
                margin-top: 20px;
              "
            >
              {{ zxnj }} 台
            </div>
          </el-card>
        </el-col>

        <el-col :span="6">
          <el-card>
            <div>
              <div style="font-weight: bold; font-size: 20px">
                全县-今日作业面积
              </div>
            </div>
            <div
              style="
                font-weight: bold;
                font-size: 20px;
                color: blue;
                margin-top: 20px;
              "
            >
              {{ jrzymj }} 亩
            </div>
          </el-card>
        </el-col>

        <el-col :span="6">
          <el-card>
            <div>
              <div style="font-weight: bold; font-size: 20px">
                全县-本作业季累计作业面积
              </div>
            </div>
            <div
              style="
                font-weight: bold;
                font-size: 20px;
                color: blue;
                margin-top: 20px;
              "
            >
              {{ bzyjljzymj }} 亩
            </div>
          </el-card>
        </el-col>

        <el-col :span="6">
          <el-card>
            <div>
              <div style="font-weight: bold; font-size: 20px">
                全县-总农机数
              </div>
            </div>
            <div
              style="
                font-weight: bold;
                font-size: 20px;
                color: blue;
                margin-top: 20px;
              "
            >
              {{ znjs }} 台
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div>
      <el-row style="height: 50px; align-items: center">
        <el-card style="margin: 20px 20px 0 20px">
          <el-row :gutter="20">
            <el-col :span="4">
              <div style="font-weight: bold; font-size: 20px; height: 100%">
                农机作业轨迹
              </div>
            </el-col>
            <el-form
              :model="form"
              label-position="left"
              label-width="80px"
              inline
              ref="form"
            >
              <el-col :span="4">
                <el-form-item>
                  <el-date-picker
                    size="mini"
                    v-model="form.year"
                    type="year"
                    format="yyyy"
                    value-format="yyyy"
                    placeholder="选择年"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item>
                  <el-select
                    size="mini"
                    v-model="form.seasonId"
                    clearable
                    placeholder="请选择作业季"
                  >
                    <el-option
                      v-for="item in seasonDataOPtions"
                      :key="item.id"
                      :label="item.workSeasonName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item>
                  <el-select
                    size="mini"
                    v-model="form.orderContent"
                    clearable
                    placeholder="请选择农事环节"
                  >
                    <el-option
                      v-for="item in linkOptions"
                      :key="item.label"
                      :label="item.label"
                      :value="item.label"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item>
                  <el-select
                    size="mini"
                    v-model="form.cropCode"
                    clearable
                    placeholder="请选择作物"
                  >
                    <el-option
                      v-for="item in zuOptions"
                      :key="item.categoryName"
                      :label="item.categoryName"
                      :value="item.categoryName"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item>
                  <el-input
                    size="mini"
                    v-model="form.companyName"
                    placeholder="请输入合作社名称"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                  <el-button
                class="query-btn"
                type="primary"
                @click="machineTrajectoryData"
                >搜索</el-button
              >
              </el-col>

            </el-form>

            <!-- <el-col :span="4">
              <el-button
                size="mini"
                icon="el-icon-zoom-in"
                type="primary"
                @click="magnify"
                >放大</el-button
              >
              <el-button
                size="mini"
                icon="el-icon-zoom-out"
                type="primary"
                @click="reduce"
                >缩小</el-button
              >
            </el-col> -->
          </el-row>
          <el-row style="margin-top: 20px">
            <el-col>
            <!-- <div class="map-container">
              <div ref="mapChart" style="width: 100%; height: 80vh">
                <div v-if="loading" class="loading-spinner">
                  <div class="loader"></div>
                </div>
              </div>
            </div> -->
            <div class="bmapgl-echarts-container">
              <!-- 百度地图容器 -->
              <div ref="bmapContainer" class="map-box">
                <div v-if="loading" class="loading-spinner">
                  <div class="loader"></div>
                </div>
              </div>
            </div>
            </el-col>
          </el-row>
        </el-card>
      </el-row>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import {
  selectSeasonByAreaCode,
  selectLinkByAreaCode,
  selectHomeworkSituationByAreaCode,
  machineTrajectoryData,
} from "@/api/njjg";
import { get } from "lodash";
// import * as echarts from "echarts";
// require("echarts/extension/bmap/bmap"); // 引入百度地图扩展

export default {
  data: () => ({
    yearData: "",
    season: "",
    link: "",
    zu: "",
    hzs: "",
    seasonDataOPtions: [],
    linkOptions: [],
    zuOptions: [],
    //上方数据
    zxnj: "",
    jrzymj: "",
    bzyjljzymj: "",
    znjs: "",
    trajectoryData: [], // 从API获取轨迹数据
    mapCenter: [116.404, 39.915], // 初始中心点
    mapZoom: 13,
    form: {
      year: "",
      seasonId: "",
      orderContent: "",
      cropCode: "",
      companyName: ""
    },
    currentBounds: null,
    chart: null,
    loading: false,
    bmap: null,
    center: null,
    map: null,            // 地图实例
    trajectories: [],     // 存储轨迹线对象
    vehicles: [],      // 车辆标记集合
    defaultConfig: {       // 默认配置
      center: [116.404, 39.915],  // 初始中心点
      zoom: 13,                   // 初始缩放级别
    },
    defaultStyle: {       // 默认轨迹样式
        strokeColor: "#3388ff",
        strokeWeight: 3,
        strokeOpacity: 0.7,
        enableClicking: true
      }
  }),

  created() {
    this.getLinkOptions();
    this.getData();
    this.getSeasonDataOPtions();
  },

  mounted() {
    //隐藏头部和底部
    let header = document.querySelector(".header");
    let noShow = document.querySelector(".noShow");
    // let homeManage = document.querySelector(".home-manage");
    let footer = document.querySelector(".footer");
    header.style = "display:none";
    footer.style = "display:none";
    noShow.style = "display:none";
    // homeManage.style = "padding-top:0;width:100vw;";

    this.initBMapGL();
    this.$nextTick(() => {
      this.machineTrajectoryData();
    });

  },
  beforeUnmount() {
    this.cleanupMap();
  },
  methods: {

    // 初始化百度地图
    initBMapGL() {
      if (!window.BMapGL) {
        console.error('BMapGL未加载成功');
        return;
      }

      this.map = new BMapGL.Map(this.$refs.bmapContainer, {
        enableAutoResize: true
      });

      // 设置初始视图
      this.map.centerAndZoom(
        new BMapGL.Point(...this.defaultConfig.center),
        this.defaultConfig.zoom
      );
      this.map.enableScrollWheelZoom(true);
      this.map.enableDragging(true);
      this.map.setMapType(BMAP_SATELLITE_MAP);
      this.map.addControl(new BMapGL.ZoomControl());

      var bdary = new BMapGL.Boundary()
      let identityObj = JSON.parse(localStorage.getItem("identityObj"));
      let that = this;
      bdary.get('舒兰市', function (rs) {
          // 获取行政区域数量
          var count = rs.boundaries.length
          var pointArray = []
          for (var i = 0; i < count; i++) {
            var path = [];
          var xyArr = rs.boundaries[i].split(';');
          var ptArr = [];
          for (var j = 0; j < xyArr.length; j++) {
              var tmp = xyArr[j].split(',');
              var pt = new BMapGL.Point(tmp[0], tmp[1]);
              ptArr.push(pt);
          }
          // 添加掩膜
          var mapmask = new BMapGL.MapMask(ptArr, {
              isBuildingMask: true,
              isPoiMask: true,
              isMapMask: true,
              showRegion: 'inside',
          });
          that.map.addOverlay(mapmask);
        }
      })
    },

      /* 清空所有轨迹 */
    clearAllTrajectories() {
      this.trajectories.forEach(line => {
        this.map.removeOverlay(line);
      });
      this.trajectories = [];

      // 清除车辆标记
      this.vehicles.forEach(vehicle => {
        this.map.removeOverlay(vehicle);
      });
      this.vehicles = [];
    },

    /* 轨迹点击处理 */
    handlePolylineClick(event, polyline) {
      // 关闭之前的信息窗口
      if (this.currentInfoWindow) {
        this.map.closeInfoWindow(this.currentInfoWindow);
      }

      // 计算信息窗口位置（取轨迹中心点）
      const path = polyline.getPath();
      const centerPoint = this.calculateCenter(path);

      // 创建信息窗口内容
      const content = `
        <div class="info-window">
          <div>农机类型：${polyline.trajData.machineType}</div>
          <div>车牌号：${polyline.trajData.carNumber}</div>
          <div>驾驶员：${polyline.trajData.driverName}</div>
          <div>本次作业时长：${polyline.trajData.workTime}</div>
          <div>服务对象：${polyline.trajData.companyName}</div>
        </div>
      `;

      // 创建信息窗口
      this.currentInfoWindow = new BMapGL.InfoWindow(content, {
        width: 200,     // 信息窗口宽度
        height: 150,    // 信息窗口高度
        offset: new BMapGL.Size(0, -30) // 位置偏移
      });

      // 打开信息窗口
      this.map.openInfoWindow(this.currentInfoWindow, centerPoint);
    },
    /* 计算轨迹中心点 */
    calculateCenter(points) {
      const lngs = points.map(p => p.lng);
      const lats = points.map(p => p.lat);

      return new BMapGL.Point(
        (Math.max(...lngs) + Math.min(...lngs)) / 2,
        (Math.max(...lats) + Math.min(...lats)) / 2
      );
    },


     /* 绘制所有轨迹 */
     drawAllTrajectories() {
      this.clearAllTrajectories(); // 先清除现有轨迹

      this.trajectoryData.forEach(traj => {
        if (traj.points.length > 0) {
          traj.points.forEach(point => {
            if (point.coords.length > 0) {
              const points = point.coords.map(p => new BMapGL.Point(p[0], p[1]));
              // 创建折线对象
              const polyline = new BMapGL.Polyline(points, {
                strokeColor: '#00BFFF',
                strokeWeight: 1,
                strokeOpacity: 0.8,
                strokeStyle: 'solid'
              });

              // 添加自定义属性存储轨迹信息
              polyline.trajData = {
                machineType: traj.machineType,
                carNumber: traj.carNumber,
                driverName: traj.driverName,
                workTime: traj.workTime,
                companyName: traj.companyName
              };

              // 添加点击事件
              polyline.addEventListener('click', e => {
                this.handlePolylineClick(e, polyline);
              });

              // 添加到地图和集合
              this.map.addOverlay(polyline);
              this.trajectories.push(polyline);
            }
          });
        }
      });
      this.drawMarker();
    },

    /* 绘制实时点 */
    drawMarker() {
      //获取最新的点
      let data = this.getLatestPoints(this.trajectoryData);
      var myIcon = new BMapGL.Icon("imgs/car.png", new BMapGL.Size(20, 20), {
        anchor: new BMapGL.Size(18, 18),  // 图标锚点
        imageSize: new BMapGL.Size(20, 20)
      });
      data.forEach((item) => {
        console.log('item',item);
        if (item != null) {
          // 创建标注对象并添加到地图
          var marker = new BMapGL.Marker(new BMapGL.Point(item.value[0], item.value[1]), {icon: myIcon});
          marker.vehicleData = item; // 绑定车辆数据

          // 添加点击事件
          marker.addEventListener('click', e => {
            this.showVehicleInfo(e.target);
          });
          this.map.addOverlay(marker);
          this.vehicles.push(marker);
        }
      });

    },
    /* 显示车辆信息 */
    showVehicleInfo(marker) {
      if (this.currentInfoWindow) {
        this.map.closeInfoWindow(this.currentInfoWindow);
      }

      const info = marker.vehicleData;
      const content = `
        <div class="vehicle-info">
          <div>农机类型：${info.machineType}</div>
          <div>车牌号：${info.carNumber}</div>
          <div>驾驶员：${info.driverName}</div>
          <div>本次作业时长：${info.workTime}</div>
          <div>服务对象：${info.companyName}</div>
        </div>
      `;

      this.currentInfoWindow = new BMapGL.InfoWindow(content, {
        width: 220,
        height: 160,
        offset: new BMapGL.Size(0, -40)
      });
      this.map.openInfoWindow(this.currentInfoWindow, marker.getPosition());
    },

    /* 清理资源 */
    cleanupMap() {
      this.clearAllTrajectories();
      if (this.map) {
        this.map.destroy();
        this.map = null;
      }
    },
    //列表数据
    async machineTrajectoryData() {
      this.loading = true;
        let params = {
          ...this.form
        }
        let res = await machineTrajectoryData(params)
        if (res.code == 0) {
          this.trajectoryData = res.data;
          this.loading = false;
        } else {
          this.loading = false;
          this.$message({
            type: 'info', // success error warning
            message: res.msg,
            duration: 2000,
          })
        }
        this.autoCenter();
    },

    dataAnalysis() {
      window.open(window.location.origin + `#/sjfx`,'_blank', 'noopener');
    },
    getData() {
       //隐藏头部和底部
    let header = document.querySelector(".header");
    console.log('header',header)
    let noShow = document.querySelector(".noShow");

    let footer = document.querySelector(".footer");
    header.style = "display:none";
    footer.style = "display:none";
    noShow.style = "display:none";
      let seasonDate = new Date();
      selectHomeworkSituationByAreaCode({
        type: "1",
        seasonDate: moment(seasonDate).format("YYYY-MM-DD HH:mm:ss"),
      }).then((res) => {
        this.zxnj = res.data.zxnj;
        this.jrzymj = res.data.jrzymj;
        this.bzyjljzymj = res.data.bzyjljzymj;
        this.znjs = res.data.znjs;
      });
    },
    handleTimeChange(event) {
      const yearData = event;
    },
    //获取作业季
    async getSeasonDataOPtions() {
      selectSeasonByAreaCode().then((res) => {
        this.seasonDataOPtions = res.data;
      });
    },
    //获取作业环节
    async getLinkOptions() {
      selectLinkByAreaCode().then((res) => {
        if (res.code == 0) {
          this.linkOptions = res.data.jobsType;
          this.zuOptions = res.data.categoryPrices;
        }
      });
    },
    magnify() {},
    reduce() {},


    // 数据清洗和转换
    processTrackData(rawData) {
          // 顶层判空
      if (!Array.isArray(rawData)) return [];

      return rawData.flatMap((track, index) => {
        // 轨迹对象有效性检查
        if (typeof track !== 'object' || track === null) return [];

        // 必需字段默认值处理
        const safeTrack = {
          machineType: '',
          carNumber: '',
          driverName: '',
          workTime: 0,
          workArea: '',
          companyName: '',
          points: [],
          ...track
        };

        // 点数据有效性检查
        if (!Array.isArray(safeTrack.points)) return [];

        return safeTrack.points.flatMap(point => {
          // 点对象基础检查
          if (typeof point !== 'object' || point === null) return [];

          // 坐标数据检查 + 默认值
          const coords = Array.isArray(point.coords) ? point.coords : [];

          return [{
            // 元数据带默认值
            machineType: safeTrack.machineType,
            carNumber: safeTrack.carNumber,
            driverName: safeTrack.driverName,
            workTime: safeTrack.workTime != null ? safeTrack.workTime : 0,
            workArea: safeTrack.workArea,
            companyName: safeTrack.companyName,
            // 处理后的坐标
            coords: coords,
            // 样式对象带默认值
            lineStyle: {
              color: "#00BFFF",
              width: 1,
              opacity: Math.min(1, Math.max(0,
                typeof safeTrack.opacity === 'number'
                  ? safeTrack.opacity
                  : 0.7
              )),
              curveness: 0,
            }
          }];
        });
      });
    },

    //获取最新的点
    getLatestPoints(rawData) {
      return rawData.flatMap(track => {
        if (track.points == null || track.points.length === 0) {
          return [];
        }
        const lastPoints = track.points[track.points.length - 1]
        var status = track.status;
        //进行中的显示实时位置
        if (status == "1") {
          if (lastPoints != null) {
            const lastPoint = lastPoints.coords[lastPoints.coords.length - 1]
            return {
              machineType: track.machineType,
              carNumber: track.carNumber,
              driverName: track.driverName,
              workTime: track.workTime != null ? track.workTime : 0,
              companyName: track.companyName,
              value: [lastPoint[0], lastPoint[1]],
            }
          }
        }

      })
    },

    // 去除连续重复点
    removeDuplicatePoints(points) {
      return points.filter((point, index) => {
        if (index === 0) return true;
        const prev = points[index - 1];
        return !(point[0] === prev[0] && point[1] === prev[1]);
      });
    },
    // 计算轨迹边界中心点
    calculateTrackCenter(tracks) {
      let minLng = Infinity,
        maxLng = -Infinity,
        minLat = Infinity,
        maxLat = -Infinity;

      // 遍历所有轨迹点
      tracks.forEach((track) => {
        if (track.points != null && track.points.length > 0) {
          track.points.forEach((point) => {
            point.coords.forEach((coord) => {
              const [lng, lat] = coord;
              minLng = Math.min(minLng, lng);
              maxLng = Math.max(maxLng, lng);
              minLat = Math.min(minLat, lat);
              maxLat = Math.max(maxLat, lat);
            });
          });
        }
      });

      // 计算中心点
      const centerLng = (minLng + maxLng) / 2;
      const centerLat = (minLat + maxLat) / 2;

      // 计算推荐缩放级别（经验公式）
      const lngDiff = maxLng - minLng;
      const latDiff = maxLat - minLat;
      const zoom = Math.floor(
        9 - Math.log(Math.max(lngDiff, latDiff * 1.5)) / Math.LN2
      );

      return {
        center: [centerLng, centerLat],
        zoom: Math.min(17, Math.max(14, zoom)),
      };
    },

    // 自动居中方法
    autoCenter() {
      var flag = false;
      if (this.trajectoryData.length > 0) {
        this.trajectoryData.forEach((item) => {
          if (item.points.length > 0) {
            flag = true;
          }
        });
      }
      if (flag) {
        const { center, zoom } = this.calculateTrackCenter(this.trajectoryData);
        this.defaultConfig.center = center;
        this.defaultConfig.zoom = 12;
        // 更新地图视图
        this.initBMapGL();
        this.drawAllTrajectories();
      } else {
        this.clearAllTrajectories();
        var myGeo = new BMapGL.Geocoder();
        // 将地址解析结果显示在地图上，并调整地图视野
        let identityObj = JSON.parse(localStorage.getItem("identityObj"));
        const self = this;
        // myGeo.getPoint(identityObj.content.areaFullName, (point) => {
          myGeo.getPoint('舒兰市', (point) => {
            if (point) {
                self.defaultConfig.center = [point.lng, point.lat];
                self.defaultConfig.zoom = 12;
                this.initBMapGL();
            } else {
                console.error('未找到该地点的坐标');
                return null;
            }
        });
      }

    },
  },
};
</script>
<style scoped>
.bmapgl-echarts-container {
  position: relative;
  width: 100%;
  height: 90vh;
}

.map-box {
  width: 100%;
  height: 100%;
}

.echarts-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* 允许穿透操作地图 */
}

.loading-spinner {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* 背景半透明 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.loader {
  border: 4px solid rgba(0, 0, 0, 0.1); /* 边框颜色 */
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f; /* 转圈颜色 */
  animation: spin 1s infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
